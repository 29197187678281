<template>
  <modal modal-class="new-connection-request"
         :hide-footer="true"
         @hide="hideModal"
         id="new-connection-request"
         ref="new-connection-request"
         size="lg">
    <template #body>
      <h2 class="title">New Connection Request</h2>
      <p class="subtitle">Classroom name:</p>
      <form-input v-model="$v.classroomName.$model"
                  :validator="$v.classroomName"
                  maxlength="50"
                  custom-class="common-input common-input--primary"/>
      <p class="subtitle">What is the age range of your students?</p>
      <select-dropdown placeholder="Select age range"
                       value-field="id"
                       :validator="$v.ageSelected"
                       v-model="$v.ageSelected.$model"
                       :state="ageRangeState"
                       :options="librarianAgeRanges"
                       :disabled="ageSelected === 'not-applicable'"
                       @input="onChangeDropdown"
                       class="custom-dropdown--ages"
                       class-select="custom-dropdown--md custom-dropdown--secondary"/>
      <p class="subtitle">Provide a few details about your school, students, or goals for this collaboration.</p>
      <form-textarea v-model.trim="$v.descriptionRequest.$model"
                     :validator="$v.descriptionRequest"
                     custom-class="common-input common-input--primary common-input--connection"/>
      <p class="subtitle">Select the topic(s) you would prefer to discuss between libraries:</p>
      <checkbox-group :options="topicsList"
                      v-model="$v.checkboxValue.$model"
                      :validator="$v.checkboxValue"
                      :is-object-array="true"
                      value-field="id"
                      text-field="name"
                      @change="onChangeCheckbox"
                      :disabled="coursesLoading"
                      variant-message-required="At least one reason must be selected"
                      custom-class="custom-checkbox-primary"/>
      <p class="subtitle subtitle-course">Select your course:</p>
      <section class="courses-container">
        <div v-if="topThreeCourses.length" class="courses-container">
          <div class="course" v-for="(course, index) in topThreeCourses"
                              :key="`${course.id}-${index}`"
                              @click="courseSelectedId = course.id"
                              :class="{'course--selected': courseSelectedId === course.id }">
            <img class="course-image" :src="setTaskImage(course)">
            <p class="course-name" :id="`course-name-${course.id}-${index}`">{{truncateString(course.name, 32)}}</p>
            <p class="course-detail" @click.stop="clickCourseDetail(course)">Learn more</p>
            <b-tooltip :target="`course-name-${course.id}-${index}`" triggers="hover" placement="bottom">
              {{ course.name }}
            </b-tooltip>
          </div>
          <div class="course" @click="clickShowMore" v-if="showMoreCourses.length">
            <p class="course-detail course-detail--show-more">Show More...</p>
          </div>
        </div>
        <div class="courses-container courses-container--not-found" v-if="!topThreeCourses.length && !coursesLoading">
          <p>No courses were found matching the entered criteria</p>
          <p>Course field is required</p>
        </div>
        <b-overlay no-wrap :show="coursesLoading"/>
      </section>
      <div class="container-buttons">
        <b-button :disabled="false"
                  class="button"
                  @click="$bvModal.hide('new-connection-request')"
                  btn-text="Cancel"/>
        <b-button :disabled="false"
                  class="button"
                  v-if="requestDetail.id"
                  @click="deleteRequest"
                  btn-text="Delete"/>
        <b-button :disabled="false"
                  class="button button--connect"
                  @click="postRequest"
                  :btn-text="textButton"/>
      </div>
      <b-overlay no-wrap :show="loading"/>
      <course-list-modal :classroomId="getClassroomData.id"
                         :is-course-library="true"
                         :courses="showMoreCourses"
                         ref="course-list-modal"
                         @on-select-course="selectCourse"
                         @close-course-list="closeCourseList"/>
      <course-detail-popup :course-pdf-url="coursePdf" ref="course-detail-popup"/>
    </template>
  </modal>
</template>

<script>
import {Button} from "@/core/components/Button"
import FormTextarea from "@/core/components/Form/Textarea"
import CheckboxGroup from '@/core/components/Form/CheckboxGroup'
import {SelectDropdown} from "@/core/components/SelectDropdown"
import Modal from '@/core/components/Modal/Modal'
import {UtilsMixins} from "@/core/Mixins"
import {CourseMixins} from "@/modules/Classroom/Mixins"
import {required} from 'vuelidate/lib/validators';
import {LibrarianService} from '@/core/Services'
import CourseListModal from '../CourseListModal'
import CourseDetailPopup from '../CourseDetailPopup'
import FormInput from "@/core/components/Form/Input"

export default {
  name: 'NewConnectionRequestPopup',
  mixins: [UtilsMixins, CourseMixins],
  components: {
    'b-button': Button,
    FormTextarea,
    CheckboxGroup,
    SelectDropdown,
    Modal,
    CourseListModal,
    CourseDetailPopup,
    FormInput
  },
  props: {
    requestDetail: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },
  data() {
    return {
      classroomName: '',
      descriptionRequest: '',
      checkboxValue: [],
      ageSelected: null,
      loading: false,
      coursesList: [],
      courseSelectedId: '',
      coursePdf: '',
      topThreeCourses: [],
      courseRequest: {},
      coursesLoading: false,
      replacedCourse: {},
      librarianAgeRanges: [],
      showMoreCourses: []
    }
  },
  validations() {
    return {
      classroomName: {
        required,
      },
      descriptionRequest: {
        required,
      },
      checkboxValue: {
        required,
      },
      ageSelected: {
        required,
      },
      courseSelectedId: {
        required,
      },
    }
  },
  async mounted () {
  },
  computed: {
    ageRangeState () {
      return (this.$v.ageSelected.$dirty && this.$v.ageSelected.$invalid) || this.ageSelected === 'not-applicable' ? 'is-invalid' : ''
    },
    textButton () {
      return this.requestDetail.id ? 'Repost request' : 'Post request'
    },
    topicsList() {
      let topicAny = {name: 'Any', id: 'any'}
      return [topicAny, ...this.topics]
    }
  },
  methods: {
    closeCourseList ({noCourseSelected}) {
      if (noCourseSelected) {
        this.replacedCourse = {}
      }
    },
    setTaskImage (course) {
      return course.tasks ? course?.tasks[0]?.task_thumbnail : ''
    },
    async clickCourseDetail (course) {
      this.coursePdf = course?.pdf_url
      await this.$nextTick()
      this.$refs["course-detail-popup"].openPopup()
    },
    selectCourse (course) {
      this.replacedCourse = this.topThreeCourses[2]
      this.topThreeCourses[2] = course
      this.courseSelectedId = course.id
      this.$bvModal.hide('course-list-modal')
    },
    async clickShowMore () {
      await this.getCoursesLibrarian(true)
      this.$refs["course-list-modal"].showModal()
    },
    truncateString (str, num) {
      return str.length > num ? str.slice(0, num) + "..." : str
    },
    async getLibrarianAgeRanges (topics) {
      const topicsId = topics.map((value) => ({id: value.id})) || []

      const resp = await LibrarianService.getLibrarianAgeRanges(topicsId)
      if (resp.success) {
        if (resp.data.length) {
          this.librarianAgeRanges = resp.data
        } else {
          this.librarianAgeRanges = [{id: 'not-applicable', name: 'Not applicable'}]
          this.ageSelected = 'not-applicable'
        }
      } else {
        console.error(resp.message ? resp.message : 'error get age ranges');
      }
    },
    async getCoursesLibrarian (showMore) {
      this.coursesLoading = true
      const filterTopics = this.checkboxValue.filter((value) => value.id !== 'any')
      const topicsId = filterTopics.map((value) => value.id)

      await LibrarianService.getLibrarianCourses({topics_id: topicsId, age_range_id: this.ageSelected}).then((resp) => {
        this.coursesList = resp.data ?? []

        //If the request has an assigned course, we remove the assigned course from the course list
        if (this.courseRequest.id) {
          this.coursesList = this.coursesList.filter((course) => course.id !== this.courseRequest.id)
        }
      })

      //If the request has an assigned course, filter the first 2 courses followed by the assigned course, otherwise filter the first 3 of the course list
      if (this.courseRequest.id) {
        this.topThreeCourses = [this.courseRequest, ...this.coursesList.slice(0, 2)]
      } else {
        this.topThreeCourses = this.coursesList.slice(0, 3)
      }
      //show all courses except the first 3 if the request does not have a course assigned or 2 if the request already has a course
      this.showMoreCourses = this.coursesList.slice(this.courseRequest.id ? 2 : 3)

      this.courseSelectedId = this.topThreeCourses[0]?.id

      if (showMore) {
        this.coursesList.unshift(this.replacedCourse)
      }

      await this.getLibrarianAgeRanges(filterTopics)

      this.coursesLoading = false
    },
    onChangeCheckbox () {
      this.ageSelected = ''
      this.getCoursesLibrarian()
    },
    onChangeDropdown () {
      this.getCoursesLibrarian()
    },
    deleteRequest () {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this request?', {
        size: 'sm',
        dialogClass: "modal-message-box purchase-center",
        centered: true,
        cancelTitle: 'Back',
      }).then((res) => {
        if (res) {
          this.loading = true
          LibrarianService.deleteRequest(this.requestDetail.id).then((res) => {
            if (res.success) {
              this.$emit('save-request')
              this.$bvModal.hide('new-connection-request')
            } else {
              const errorMessage = res.message ? res.message : 'Error deleting request.'
              this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
                size: 'sm',
                dialogClass: 'modal-message-box',
                centered: true
              })
            }
          }).finally(() => this.loading = false)
        }
      })
    },
    async open () {
      this.$v.$reset()

      this.courseRequest = {}
      this.descriptionRequest = ''
      this.classroomName = ''
      this.checkboxValue = []
      this.ageSelected = null
      this.topThreeCourses = []

      this.$bvModal.show('new-connection-request')

      if (this.requestDetail.id) {
        this.loading = true
        await LibrarianService.getRequestById(this.requestDetail.id).then((res) => {
          if (res.success) {
            const request = res?.data
            this.descriptionRequest = request.text_request
            this.checkboxValue = request.topics?.length ? request.topics : [{name: 'Any', id: 'any'}]
            this.ageSelected = request.age_range_id
            this.courseRequest = request.course ?? {}
            this.classroomName = request.classroom_name

            if (this.courseRequest?.id) {
              this.topThreeCourses.unshift(this.courseRequest)
              this.topThreeCourses = this.topThreeCourses.slice(0, 3)
              this.courseSelectedId = this.topThreeCourses[0]?.id
            }
          } else {
            const errorMessage = res.message ? res.message : 'Error get request.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            })
          }
        }).finally(() => this.loading = false)
      }

      await this.getCoursesLibrarian()

      if (this.coursesList.length) {
        this.courseSelectedId = this.topThreeCourses[0].id
      }
    },
    hideModal () {
    },
    postRequest () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true

      const data = {
        "text_request": this.descriptionRequest,
        "age_range_id": this.ageSelected,
        "topics": this.checkboxValue[0]?.id === 'any' ? [] : this.checkboxValue,
        "course_id": this.courseSelectedId,
        "classroom_name": this.classroomName,
      }
      if (this.requestDetail.id) {
        LibrarianService.updateRequest({requestId: this.requestDetail.id, requestData: data}).then((res) => {
          if (res.success) {
              this.$bvModal.msgBoxOk('Successful!', {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            }).then(() => {
              this.$emit('save-request')
              this.$bvModal.hide('new-connection-request')
            })
          } else {
            const errorMessage = res.message ? res.message : 'Error saving the request.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
              size: 'sm',
              dialogClass: 'modal-message-box',
              centered: true
            })
          }
        }).finally(() => {
          this.loading = false
        })
        return
      }
      LibrarianService.createNewRequest(data).then((res) => {
        if (res.success) {
            this.$bvModal.msgBoxOk('Successful!', {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          }).then(() => {
            this.$emit('save-request')
            this.$bvModal.hide('new-connection-request')
          })
        } else {
          const errorMessage = res.message ? res.message : 'Error saving the request.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    checkboxValue (newValue) {
      if (newValue[newValue.length - 1]?.id === 'any' && newValue.length > 1) {
        this.checkboxValue = [{name: 'Any', id: 'any'}]
      }
      if (newValue[newValue.length - 1]?.id !== 'any' && newValue?.find((value) => value.id === 'any')) {
        this.checkboxValue = this.checkboxValue.filter((value) => value.id !== 'any')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

#new-connection-request
  .avatar-list-container
    overflow-y scroll
    padding 5px
    padding-bottom 15px
    max-height 65vh

  .title
    font font-opensans-bold
    font-size 22px
    text-align center
    margin-bottom 20px

  .subtitle
    font font-opensans-semibold
    margin 0
    margin-bottom 12px

  .subtitle-course
    margin-top 12px

  .courses-container
    width 100%
    display flex
    justify-content center
    flex-wrap wrap
    position relative

    .course
      text-align center
      font font-opensans-semibold
      width 140px
      height 185px
      padding 5px
      border 2px solid color-3
      color color-3
      border-radius 4px
      margin 0 10px
      position relative
      margin-bottom 10px
      cursor pointer

      .course-image
        object-fit cover
        width 100%
        height 80px
        margin-bottom 5px

      .course-name
        text-transform uppercase
        margin-bottom 10px
        width 100%
        word-break break-word

      .course-detail
        bottom 5px
        cursor pointer
        width 100%
        text-decoration underline
        font-size 12px
        position absolute
        margin 0 auto
        left 0

      .course-detail--show-more
        position static
        display flex
        align-items center
        justify-content center
        font-size 14px
        height 100%
        text-transform uppercase

    .course--selected
      border-color color-5
      outline 3px solid color-5

  .courses-container--not-found
    flex-direction column
    color color-38

  .container-buttons
    width 100%
    display flex
    align-items flex-end
    height 100%
    justify-content space-around
    flex-wrap wrap
    margin-top 10px

    .button
      font font-opensans-semibold
      text-transform capitalize
      height fit-content
      margin-top 10px

    .button--connect
      margin 0
      margin-top 10px

.custom-dropdown--ages
  margin-bottom 12px

::v-deep
  .common-input--connection
    resize none

  .custom-checkbox-primary
    column-count 1 !important

    .custom-control-inline
      display flex

      .custom-control-label
        font-size 15px
</style>
<style lang="stylus">
.new-connection-request
  .modal-body
    .body
      padding 3rem
      @media (max-width 400px)
        padding 1rem
</style>
