import { AppStorage } from './index'
import axios from 'axios'

function isValidUrl(url) {

  if (!url || !url.includes('.')) {
    return false
  }

  try {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    return !!pattern.test(url);
  } catch (e) {
    return false
  }

  // const regexQuery = "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
  // const newUrl = new RegExp(regexQuery,"i");
  // return newUrl.test(url);
}
function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function httpUrl(url) {
  const protocol = (n) => url.substring(0, n)
  return protocol(8) === 'https://' || protocol(7) === 'http://' ? url : 'https://' + url
}

function currentPercentage(current, total) {
  return total ? ((current || 0) * 100) / total : 0
}

function isValidFile(validFormats, file) {
  if (!validFormats || !file) {
    return false
  }

  return validFormats.indexOf(file.type) > -1;
}

function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

function padNumber(num, size) {
  let s = String(num);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

function stringToTimeFormat(str) {
  return str.match(/.{1,2}/g);
}

function timeFormatToInt(timeVal) {
  return parseInt(timeVal.split(":").join(""))
}

function splitArrayInEqualsPart(arr, size) {
  const result = new Array(Math.ceil(arr.length / size)).fill().map(() => arr.splice(0, size))
  return result
}

function strIsNumeric(value) {
  return /^\d+$/.test(value);
}

function isNumericFormat(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function formatCurrency(value) {
  const globalSetup = AppStorage.getGameGlobalSetup()
  let valueFormatted = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  if (globalSetup.currencySymbolPosition === 'left') {
    valueFormatted = globalSetup.currencySymbol + " " + valueFormatted
  } else {
    valueFormatted = valueFormatted + " " + globalSetup.currencySymbol
  }
  return valueFormatted;
}

function isLongTextToBtn(text) {
  return text ? text.length > 10 : false
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const bytesToKB = (bytes, decimals = 3) => {
  const dm = decimals < 0 ? 0 : decimals
  return parseFloat((bytes / 1024).toFixed(dm)) ?? ''
}

function isValidPhoneNumber(phone) {
  const phoneRe = /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im;
  return phoneRe.test(phone)
}

function isValidLetter(letter) {
  const letterRegex = /^[A-Za-zñçÀ-úâ-û]+$/
  return letterRegex.test(letter)
}

const isValidNumber = (number) => {
  return /^[0-9]*$/.test(number)
}

const isNumberStudentsAllowed = (number) => {
  return number >= 2 && number <= 30
}

const sortBy = (field, reverse, primer) => {

  const key = primer ?
    function (x) {
      return primer(x[field])
    } :
    function (x) {
      return x[field]
    };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
  }
}

const isAlphanumeric = (word) => {
  const wordRe = /^[A-Za-z0-9\d\-_\s]+$/
  return wordRe.test(word)
}

const runSpeedTest = () => {
  const data = new TextEncoder().encode('1234567890123456789012345678901234567890')
  const startTime = new Date()

  // Send the file to a remote server
  const url = 'https://jsonplaceholder.typicode.com/posts'

  return axios.post(url, data).then(() => {
    const endTime = new Date()

    // Get the upload time
    const uploadTime = (endTime - startTime) / 1000

    // Calculate the upload speed
    const speedInKbps = (data.length / uploadTime) / 1024
    const speed = speedInKbps.toFixed(2) * 100
    console.log(speed)

    return speed
  })
}

const isPlayerTrustApp = () => {
  const currentUrl = window.location.href
  const containsPlayers = /players/i.test(currentUrl);
  return containsPlayers
}

export default {
  isValidUrl,
  isValidHttpUrl,
  isValidFile,
  isEmptyObj,
  padNumber,
  stringToTimeFormat,
  timeFormatToInt,
  splitArrayInEqualsPart,
  strIsNumeric,
  isNumericFormat,
  formatCurrency,
  isLongTextToBtn,
  currentPercentage,
  formatBytes,
  bytesToKB,
  isValidPhoneNumber,
  sortBy,
  isAlphanumeric,
  isValidNumber,
  isValidLetter,
  httpUrl,
  runSpeedTest,
  isNumberStudentsAllowed,
  isPlayerTrustApp
}
