<template>
  <media-query-provider :queries="$options.queries">
    <router-view/>
  </media-query-provider>
</template>
<script>
import {MediaQueryProvider} from 'vue-component-media-queries'

const queries = {
  mobile: '(max-width: 767px)',
  tablet: '(max-width: 1024px)'
}
export default {
  name: 'App',
  queries,
  components: {
    MediaQueryProvider
  },
  created () {
    console.log('Node new version. test');
  }
}
</script>
