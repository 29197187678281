<template>
  <div class="tab-container-my-students">
    <div class="students-actions-wrapper">
      <b-button @click="$refs['column_selection_modal'].showModal()"
                v-if="!isSmallGroupType"
                class="size-text-btn"
                btn-text="DOWNLOAD STUDENT LIST"
                outline
                small/>
      <div class="students-actions">
        <!-- hide add group now the system will create them -->
        <classroom-detail-group-add-new @Student:Created:success="studentCreated"
                                        @on-add-student="getStudents"
                                        v-if="false"
                                        v-bind="{...$props}"/>

        <classroom-detail-student-add-new @Student:Created:success="studentCreated"
                                          v-if="!isSmallGroupType"
                                          @on-add-student="getStudents"
                                          v-bind="{...$props}"/>
        <classroom-detail-student-upload-template @Student:Imported:success="studentImported"
                                                  v-bind="{...$props}"
                                                  v-if="!isSmallGroupType"/>
      </div>
    </div>
    <classroom-detail-tab-filter :dropdown-view-show="false"
                                 :filters-by="filterBy"
                                 v-model="filters"
                                 :disabled="!students.length"
                                 @Tasks:Search="search()"
                                 default-view="grid"
                                 placeholder="ORDER BY"/>
    <hr>
    <p class="students-description">
      Click on See Profile to view a student’s details.
    </p>

    <div v-if="validFirstMessage" class="message-container text-center">
      <p id="cancel-label">Thank you! When your partner teacher enters their number of students we will create the groups for both classrooms!</p>
    </div>

    <div v-else-if="validSecondMessage" class="message-container text-center">
      <p id="cancel-label">Please wait while we create your student groups...</p>
      <b-spinner label="Spinning"/>
    </div>

    <div v-if="showStudentsTable" class="students-body" :class="{'students-body--small-group': isSmallGroupType}">
      <div class="students-body-view">
        <div class="table-row header">
          <div class="column column--small-group"></div>
          <div class="column column-name">NAME</div>
          <div class="column column-username" v-if="showUserNameColumn">User Name</div>
          <div class="column column--partner"
               v-if="isSmallGroupType"
               id="tooltip-partner"
               v-b-tooltip="headTooltips.partner">
            Suggested Partner Group</div>
          <div class="column column-permission column--post"
               v-b-tooltip="headTooltips.post">
            Allow post
          </div>
          <div class="column column-course-completion column-course-completion--title">COURSE COMPLETION</div>
          <div v-if="getClassroomData.paymentRequired" class="column column__paid">Paid</div>
          <div class="column column-link"></div>
        </div>
        <div class="table-row" :key="`table-row-${index}`" v-for="(student, index) in students">
          <div class="column column-image">
            <div class="image">
              <b-avatar :src="student.thumbnail"></b-avatar>
            </div>
          </div>
          <div class="column column-name padding-name" v-if="isSmallGroupType && !isApprovedName">
            <input-group-name :group-name="student.first_name"
                              @has-error="(error) => groupNamesErrors(student.id, error)"
                              @input="(name) => {
                                if(name) {
                                  if(student.first_name !== name.trim()) {
                                    student.newGroupName = name
                                  }
                                }}"/>
          </div>
          <div class="column column-name padding-name" v-else>{{ student.first_name }} {{ isIndividualGroupType ? student.last_initial : '' }}</div>
          <div class="column column-username" v-if="showUserNameColumn">
            <copy-text-element :text="student.username"/>
          </div>
          <div class="column column-partner" v-if="isSmallGroupType">
            <div class="partner-label">{{ student.partner_is_approved_name ? student.partner_name : 'Pending...' }}</div>
          </div>
          <div class="column column-permission">
            <b-form-checkbox class="custom-checkbox-primary" v-if="!loading"
                             v-model="student.pivot.approve_student_submissions"
                             :disabled="studentsUnderThirteenYearsOld"
                             @change="onUpdateSubmissionsPermissions(student)"/>
          </div>
          <div class="column column-course-completion">
            <b-progress class="progress-course" height="1.81rem" :max="100">
              <b-progress-bar :value="progressInBar(student)"></b-progress-bar>
              <span :style="studentCompletionLabelStyle(student)">{{ studentCompletionLabel(student) }}</span>
            </b-progress>
          </div>
          <div v-if="getClassroomData.paymentRequired" class="column column__paid">
            <b-form-checkbox  class="custom-checkbox-primary"
                              :checked="student.hasPayments"
                              :disabled="true" />
          </div>
          <div class="column column-link">
            <a class="link-profile"
               @click="onSeeProfileStudent(student)"
               :class="{'link-profile--disable': isSmallGroupType && !isApprovedName}"
               href="javascript:void(0)">See Profile</a>
          </div>
        </div>
      </div>
    </div>
    <b-overlay no-wrap :show="loading"></b-overlay>
    <b-button @click="approveGroups" v-if="isSmallGroupType && students.length && !isApprovedName" class="button-approve" btn-text="Approve" :disabled="nameGroupError"/>
    <modal ref="column_selection_modal">
      <template #body>
        <h2 class="modal-title">Select columns to export</h2>
        <form-checkbox-group :options="columns"
                             v-model="columnsSelected"
                             custom-class="custom-checkbox-primary"/>
      </template>
      <template #footer>
        <b-button @click="exportStudents" btn-text="Download"/>
      </template>
    </modal>
    <students-number-modal :classroom="classroom" @save-number-students="saveNumberStudents" @change-tab="$emit('change-tab')"/>
    <classroom-detail-group-name-review :students-number="classroom.students_number" :number-groups="students.length"/>
  </div>
</template>

<script>
import ClassroomDetailTabFilter from "@/modules/Classroom/Components/ClassroomDetail/ClassroomDetailTabFilter";
import ClassroomDetailStudentAddNew from "./ClassroomDetailStudentAddNew";
import ClassroomDetailGroupAddNew from './ClassroomDetailGroupAddNew';
import ClassroomDetailStudentUploadTemplate from "./ClassroomDetailStudentUploadTemplate";
import {ClassroomService} from '@/core/Services'
import {Button} from "@/core/components/Button";
import {Utils} from "@/core/Utils";
import CopyTextElement from "@/core/components/Copy/CopyTextElement";
import {AppStorage} from "@/core/Utils";
import {Modal} from "@/core/components/Modal"
import FormCheckboxGroup from "@/core/components/Form/CheckboxGroup"
import {CourseMixins} from "@/modules/Classroom/Mixins"
import StudentsNumberModal from './StudentsNumberModal';
import ClassroomDetailGroupNameReview from './ClassroomDetailGroupNameReview';
import ClassroomDetailTabInputGroupName from './ClassroomDetailTabInputGroupName'

export default {
  name: "ClassroomDetailTabItemStudents",
  mixins: [CourseMixins],
  components: {
    CopyTextElement,
    ClassroomDetailStudentAddNew,
    ClassroomDetailGroupAddNew,
    ClassroomDetailStudentUploadTemplate,
    ClassroomDetailTabFilter,
    'b-button': Button,
    FormCheckboxGroup,
    Modal,
    StudentsNumberModal,
    ClassroomDetailGroupNameReview,
    'input-group-name': ClassroomDetailTabInputGroupName
  },
  data() {
    return {
      filters: {
        filterBy: null,
        search_string: null,
      },
      students: [],
      filterBy: [
        {
          name: "A - Z",
          type: "order",
          value: "asc"
        },
        {
          name: "Z - A",
          type: "order",
          value: "desc"
        }
      ],
      currentStudentUserId: null,
      loading: false,
      columns: [
        {text: 'First Name', value: 'first_name'},
        {text: 'Last Initial', value: 'last_initial'},
        {text: 'Username', value: 'username'},
        {text: 'Videos', value: 'videos'},
        {text: 'Responses', value: 'responses'},
        {text: 'ID', value: 'id'},
        {text: 'User ID', value: 'user_id'},
        {text: 'Updated At', value: 'updated_at'},
        {text: 'Thumbnail', value: 'thumbnail'}
      ],
      columnsSelected: [
        'first_name',
        'last_initial',
        'username',
        'videos',
        'responses'
      ],
      groups: [],
      nameGroupError: false
    }
  },
  props: {
    classroom: Object,
  },
  async mounted() {
    await this.getStudents()

    if (this.isSmallGroupType) {
      if (!this.classroom?.students_number && !!this.classroom?.partner_id && !this.students.length) {
        this.$bvModal.show('number-students-modal')
      }
      if (this.students.length && !this.isApprovedName) {
        this.$bvModal.show('group-name-review-modal')
      }
      const resp = await ClassroomService.getSmallGroupsNames()
      this.groups = resp?.data?.map(({...group}) => {
        group.name = group.group_name
        return group
      })
    }
  },
  methods: {
    async saveNumberStudents() {
      await this.getStudents()
      if (this.students.length && !this.isApprovedName) {
        this.$bvModal.show('group-name-review-modal')
      }
    },
    groupNamesErrors(id, hasError) {
      const studentsList = this.students.map(({...student}) => {
        if (student.id === id) {
          student.hasError = hasError
        }
        return {...student}
      })

      this.students = studentsList
      this.nameGroupError = !!this.students.find((student) => student.hasError)
    },
    async search() {
      await this.getStudents()
      if (!this.students.length) {
        this.$bvModal.msgBoxOk('No match found.', {
          size: 'sm',
          dialogClass: 'modal-message-box',
          centered: true
        }).then(() => {
          this.filters.search_string = ''
          this.getStudents()
        })
      }
    },
    approveGroups() {
      this.loading = true
      const groupNames = []

      this.students.map((student) => {
        groupNames.push({
          id: student.id,
          group_name: student.newGroupName ? student.newGroupName : student.first_name,
          username_prefix: student.newGroupName ? student.newGroupName?.replace(/\s+/g, '') : student.username
        })
      })

      const data = {classroom_id: this.classroom.id, groups: groupNames}

      ClassroomService.approveGroupsNames(data).then((resp) => {
        if (resp.success) {
          this.getStudents()
        }
      }).catch((res) => {
        const errorMessage = res.message ? res.message : 'Error reporting.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
            size: 'sm',
            dialogClass: 'modal-message-box',
            centered: true
          })
      }).finally(() => this.loading = false)
    },
    onSeeProfileStudent(student, currentTab = 0) {
      if (this.isSmallGroupType && !this.isApprovedName) {
        return
      }
      this.$emit('on-change-component', {
        component: 'ClassroomDetailTabStudentDetail',
        params: {student: student, currentTabIndex: currentTab}
      })
    },
    async getStudents() {
      this.loading = true

      let filters = {
        search_string: this.filters.search_string,
        percentage_by_category: 1
      }

      if (this.filters.filterBy) {
        filters.filter_by = this.filters.filterBy.type === 'filter' ? this.filters.filterBy.value : ''
        filters.order_by = this.filters.filterBy.type === 'order' ? this.filters.filterBy.value : ''
      }


      await ClassroomService.getStudents({classroom_id: this.classroom.id, filters: filters}).then(response => {
        this.students = response.data.map((student) => {
          const currentStudent = {...student}

          if(this.getClassroomData.paymentRequired){
            Object.assign(currentStudent, {hasPayments: !!student.payments.length })
          }

          currentStudent.pivot.approve_student_submissions = currentStudent.pivot.approve_student_submissions === 1

          return currentStudent
        })

        if (this.currentStudentUserId && this.students.length) {
          const currentStudent = this.students.find(student => student.user_id === this.currentStudentUserId)

          if (currentStudent)
            this.onSeeProfileStudent(currentStudent, 1)
        }

        this.loading = false
      }).catch(e => {
        const errorMessage = e.message ? e.message : 'Error trying to get students.'

        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          dialogClass: "modal-message-box",
          noCloseOnBackdrop: true,
          centered: true
        })
      })
    },
    onUpdateSubmissionsPermissions(student) {
      this.loading = true
      ClassroomService.setStudentPermissions({
        classroom_id: this.classroom.id,
        approve_student_submissions: student.pivot.approve_student_submissions,
        students: [student]
      }).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    studentCreated(student) {
      this.students.unshift(student)
    },
    studentImported(students = []) {
      this.students.unshift(...students)
    },
    studentCompletions(student) {
      const totalCount = student.submissions_progress["to-do"] +
        student.submissions_progress["submitted"] +
        student.submissions_progress["approved"] +
        student.submissions_progress["blocked"] +
        student.responses_progress["to-do"] +
        student.responses_progress["submitted"] +
        student.responses_progress["approved"] +
        student.responses_progress["blocked"]

      const approvedCount = student.submissions_progress["approved"] +
        student.responses_progress["approved"]

      return Utils.currentPercentage(approvedCount, totalCount)
    },
    studentCompletionLabel(student) {
      const percent = this.progressInBar(student)

      return percent >= 100 ? 'Completed' : percent + '%'
    },
    studentCompletionLabelStyle(student) {
      const percent = this.progressInBar(student)

      if (percent === 100) {
        return {width: '100%', textAlign: 'center'}
      }

      if (percent >= 72) {
        return {right: 0}
      }

      return {marginLeft: percent + '%', left: 0}
    },
    progressInBar(student) {
      const percent = this.studentCompletions(student)

      return percent >= 0.5 ? percent.toFixed() : 0
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },
    exportStudents() {
      let filters = {
        search_string: this.filters.search_string
      }

      if (this.filters.filterBy) {
        filters.filter_by = this.filters.filterBy.type === 'filter' ? this.filters.filterBy.value : ''
        filters.order_by = this.filters.filterBy.type === 'order' ? this.filters.filterBy.value : ''
      }
      const dataExport = {
        classroom_id: this.classroom.id,
        filters: filters,
      }
      if (this.columnsSelected.length) {
        dataExport['columns'] = this.columnsSelected
      }
      ClassroomService.exportStudents(dataExport).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'students.xlsx');
        document.body.appendChild(link);
        link.click();
      });
    }
  },
  computed: {
    studentsUnderThirteenYearsOld() {
      return this.classroom.age_range ? this.classroom.age_range.min_age < 13 : true
    },
    validFirstMessage() {
      return this.classroom.students_number && !this.classroom.partner?.classrooms[0]?.students_number && this.isSmallGroupType && !this.loading && !this.students.length && !this.filters.search_string
    },
    validSecondMessage() {
      return !this.classroom.students_number && this.classroom.partner?.classrooms[0]?.students_number && this.isSmallGroupType  && !this.loading && !this.students.length && !this.filters.search_string
    },
    showStudentsTable() {
      return this.students.length
    },
    isApprovedName() {
      return !!this.students[0]?.is_approved_name
    },
    showUserNameColumn() {
      return (this.isSmallGroupType && this.isApprovedName) || !this.isSmallGroupType
    },
    headTooltips() {
      return {
        partner: {
          title: 'Student groups are able to respond to any group that has not yet received a response. If you wish to use fixed group partnerships, you may use the suggested pairings here and have students respond only to that group.',
          disabled: !this.isSmallGroupType,
          trigger:'hover',
          customClass: 'head-tooltip'
        },
        post: {
          title: 'By selecting the box, students will be able to post videos without teacher approval. You will still be able to review these videos at any time in the Submissions tab.',
          trigger:'hover',
          customClass: 'head-tooltip'
        }
      }
    }
  },
  watch: {
    '$route.query.notification_id': {
      handler: function (notification_id) {
        if (notification_id) {
          const notificationOpen = AppStorage.getNotificationOpen(notification_id);
          if (notificationOpen && notificationOpen.trigger_user_id) {
            if (typeof notificationOpen.trigger_user_id === 'string') {
              this.currentStudentUserId = parseInt(notificationOpen.trigger_user_id)
            } else {
              this.currentStudentUserId = notificationOpen.trigger_user_id
            }
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"

.tab-container-my-students
  padding-left 10px
  padding-right 10px
  padding-bottom 99px
  padding-top 41px

::v-deep
  .btn
    letter-spacing 0
    border-radius 7px

  .btn-common--small
    padding 4px 12px
    border-width 2px
    border-color color-3
    font-size 10.5px


@media (min-width: 768px)
  .tab-container-my-students
    padding-left 50px
    padding-right 50px

@media (min-width: 992px)
  .tab-container-my-students
    padding-left 112px
    padding-right 112px

  ::v-deep
    .btn-common--small
      padding 6px 20px
      font-size 11px

@media (min-width: 1200px)
  ::v-deep
    .btn-common--small
      padding 8px 30px
      font-size 13px

.students-actions-wrapper
  flexbox(flex)
  justify-content(space-between)
  padding-bottom 15px

.students-actions
  flexbox(flex)
  justify-content(end)

  ::v-deep
    button
      margin-left 5px

.students-description
  font-size 14px
  font font-opensans-regular

.message-container
  padding 50px 0

.students-body
  &-view
    position relative
    display grid
    grid-template-columns 1fr
    width 100%

    .table-row
      padding 6px 8px
      border 1px solid color-28
      align-items center
      font font-opensans-regular
      font-size 10px
      margin-bottom 4px

      &.header
        background-color rgba(color-27, 0.27)
        color color-8
        font font-opensans-bold
        font-size 10px
        border 0
        margin-bottom 1px
        text-transform uppercase

        .column
          &-username
            padding-right 5px

          &-permission
            margin-right 5px
            margin-left -5px

          &--partner
            flex-basis 14%

          &--post, &--partner
            cursor pointer

      & > .column
        &:first-child
          width 35px

      .column
        .image
          width 30px

          ::v-deep
            .b-avatar
              height 30px

        .link-profile
          color color-3
          font font-opensans-bold
          font-size 10.5px
          text-align right

        &-link
          flex-basis(12%)
          text-align center

        &-name
          text-transform capitalize

        &-name
        &-username
          flex-basis(16%)

        &-course-completion
          flex-basis(20%)

        &-permission
          text-align center
          flex-basis(10%)

        &__paid
          text-align center
          flex-basis(8%)

      .wrapper
        flex-grow(1)
        flex-direction(column)

        .column
          width 50%

      @media (min-width: 475px)
        .column-name
          padding-left 10px
          margin-right 8px

        .column .image
          width 35px

          ::v-deep
            .b-avatar
              height 35px

      @media (min-width: 768px)
        padding 6px 12px
        font-size 11.5px
        .wrapper
          flex-direction(row)

        &.header
          font-size 12px

        .column .link-profile
          font-size 12px

        .column:first-child
          width 60px

        .column-name
          padding-left 0
          margin-right 0


  @media (min-width: 1200px)
    &-view
      .table-row
      font-size 13px

      &.header
        font-size 14px

        .column
          &-username
            padding-right 0

          &-permission
            margin-left -13px


    .table-row .column
      &:first-child
        width 60px

      .link-profile
        font-size 13px

      &-name
      &-username
        flex-basis(21%)

      &-permission
        margin-right 5px
        margin-left -13px
        flex-basis(15%)


    ::v-deep
    .btn-common--small
      padding 8px 30px
      font-size 13px

.students-body--small-group
  overflow-x auto
  height auto
  min-height 160px
  padding 8px 0 !important

  .students-body-view
    min-width 900px

    .table-row
      .column
        &-select
          width 200px !important

        .select-container
          width 90%

          ::v-deep
            .dropdown-menu
              height 150px !important

        &-image
          width 40px !important

        .image
          width 30px

          ::v-deep
            .b-avatar
              height 30px

        .link-profile
          color color-3
          font font-opensans-bold
          font-size 10.5px
          text-align right

        .link-profile--disable
          cursor not-allowed

        &-link
          flex-basis(12%)
          text-align center

        &-name
          text-transform capitalize
          flex-basis(22%)
          text-overflow ellipsis
          white-space nowrap
          overflow hidden
          margin-right 10px
          padding-right 20px
          padding-left 10px

        &-partner
          flex-basis(15%) !important

          .partner-label
            width 100px !important
            text-overflow ellipsis
            white-space nowrap
            overflow hidden
            max-width 100px

        &-username
          flex-basis(14%)
          width 150px !important
          text-overflow ellipsis
          white-space nowrap
          overflow hidden
          max-width 150px

        &-course-completion
          flex-basis(23%)

          &--title
            margin-left 20px

        &-permission
          text-align center
          flex-basis(9%)

        &__paid
          text-align center
          flex-basis(8%)

      .wrapper
        flex-grow(1)
        flex-direction(column)

        .column
          width 50%

      .column--small-group
        width 40px

      @media (min-width: 475px)
        .column-name
          padding-left 10px
          margin-right 10px

        .column .image
          width 35px

          ::v-deep
            .b-avatar
              height 35px

      @media (min-width: 768px)
        padding 6px 12px
        font-size 11.5px
        .wrapper
          flex-direction(row)

        &.header
          font-size 12px

        .column .link-profile
          font-size 12px

        .column-username
          flex-basis(14%)

      @media (min-width: 1400px)
        .column-partner
          .partner-label
            max-width 150px
            width 150px !important

.progress-course
  position relative
  background-color color-20
  border-radius 0
  border 2px solid color-36
  padding 2px 3px
  margin-right 10px

  ::v-deep
    .progress-bar
      background-color color-37

  span
    font font-opensans-bold
    font-size 13px
    position absolute
    margin-top 2px
    padding-left 7.65px
    padding-right 7.65px
    top 0

::v-deep
  .modal-dialog
    margin auto

  .modal-message-box .modal-content
    align-items center
    padding 5px

    .modal-body
      margin-bottom 0
      text-align center

      &::first-letter
        text-transform uppercase

    .modal-footer
      margin-bottom 12px

      .btn-primary
        background-color color-3
        border-color color-7
        font font-opensans-semibold
        border-radius 0.25rem

</style>
<style lang="stylus">
.head-tooltip
  .tooltip-inner
    max-width 700px
</style>
