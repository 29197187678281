import { render, staticRenderFns } from "./MyClassroomsView.vue?vue&type=template&id=a0e7a684&scoped=true"
import script from "./MyClassroomsView.vue?vue&type=script&lang=js"
export * from "./MyClassroomsView.vue?vue&type=script&lang=js"
import style0 from "./MyClassroomsView.vue?vue&type=style&index=0&id=a0e7a684&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0e7a684",
  null
  
)

export default component.exports