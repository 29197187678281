<template>
  <modal id="course-pdf-popup"
         ref="course-pdf-popup"
         size="xl"
         @hidden="coursePdf = ''">
    <template #body>
      <iframe v-if="coursePdf" :src="coursePdf + '#toolbar=0'" width="100%" height="100%"
              @load="coursePdfLoaded = true"/>

      <img v-else-if="courseImage" :src="courseImage + '#toolbar=0'" width="100%" height="100%"
          @load="coursePdfLoaded = true" :alt="courseImage + '#toolbar=0'"/>

      <a v-else-if="courseLink" :href="courseLink + '#toolbar=0'">{{ courseLink + '#toolbar=0' }}</a>

      <div v-else-if="coursePdfError" class="pdf-error">
        FILE NOT FOUND <br> <br>
        <b>{{ coursePdfError }}</b>
      </div>

      <b-overlay no-wrap :show="!coursePdfLoaded"/>
    </template>
    <template #footer>
      <b-button @click="$bvModal.hide('course-pdf-popup')" btn-text="CLOSE"/>
    </template>
  </modal>
</template>

<script>
import {Button} from "@/core/components/Button"
import Modal from '@/core/components/Modal/Modal'
import {UploadService} from "@/core/Services"
import axios from 'axios'

export default {
  name: "CourseDetailPopup",
  components: {
    'b-button': Button,
    Modal
  },
  data() {
    return {
      coursePdf: '',
      courseImage: '',
      courseLink: '',
      coursePdfError: '',
      coursePdfLoaded: false
    }
  },
  props: {
    coursePdfUrl: {
      required: false
    },
  },
  methods: {
    async openPopup () {
      this.coursePdf = ''
      this.courseImage = ''
      this.courseLink = ''
      this.coursePdfError = ''
      this.coursePdfLoaded = false

      try {
        let response = await axios.get(this.coursePdfUrl, {
          responseType: 'blob', // had to add this one here
        })

        const extension = UploadService.getExtension(this.coursePdfUrl)

        if (extension === 'pdf') {
          this.coursePdf = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}))
        } else if (this.coursePdfUrl.match(/.(jpg|jpeg|png|gif)$/i)) {
          this.courseImage = window.URL.createObjectURL(new Blob([response.data], {type: "image/" + extension}))
        } else {
          this.courseLink = this.coursePdfUrl
          this.coursePdfLoaded = true
        }
      } catch (e) {
        this.coursePdfError = this.coursePdfUrl
        this.coursePdfLoaded = true
      }

      this.$refs['course-pdf-popup'].showModal()
    }
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"

#course-pdf-popup
  iframe
    height calc(100vh - 222px)
</style>
