<template>
  <modal modal-class="course-list-modal"
         :hide-footer="true"
         @hide="hideModal"
         id="course-list-modal"
         ref="course-list-modal"
         size="xl">
    <template #body>
      <div class="wrapper-classroom-content">
        <div class="wrapper-classroom-content__inner wrapper-classroom-content__inner--course-library">
          <div class="container-course-content" v-if="false">
            <div class="container-header-filters">
              <div class="container-item-filter">
                <div class="common-search-bar-container">
                  <input v-on:keyup.enter="getCoursesForClassroom(true)" type="text" placeholder="SEARCH"
                         class="common-search-bar" v-model="searchString">
                </div>
              </div>
            </div>
          </div>
          <div class="separator-filters"></div>
          <div class="container-course-content">
            <div class="container-courses">
              <div v-for="(course, index) in courses"
                   class="course"
                   :key="`${course.id}-${index}`"
                   @click="selectCourse(course)">
                <img class="course-image" :src="setTaskImage(course)">
                <p class="course-name" :id="`course-name-${course.id}-${index}`">{{truncateString(course.name, 32)}}</p>
                <p class="course-detail" @click.stop="seeCourseDetails(course)">Learn more</p>
                <b-tooltip :target="`course-name-${course.id}-${index}`" triggers="hover" placement="bottom">
                  {{ course.name }}
                </b-tooltip>
              </div>
              <b-overlay no-wrap :show="isLoading"/>
            </div>
          </div>
          <modal ref="no_results_modal">
            <template #header>
            </template>
            <template #body>
              <h2 class="modal-title">NO COURSES FOUND</h2>
              <p class="text-center">
                No courses were found matching the entered criteria.
              </p>
            </template>
            <template #footer>
              <b-button @click="closeNoResultsModal()" btn-text="OK"/>
            </template>
          </modal>
          <course-detail-popup :course-pdf-url="coursePdf" ref="course-detail-popup"/>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/core/components/Modal/Modal'
import CourseDetailPopup from './CourseDetailPopup'

export default {
  name: "CourseListModal",
  components: {
    Modal,
    CourseDetailPopup
  },
  data() {
    return {
      selectedCourse: {},
      coursePdf: '',
      isLoading: false,
      searchString: ''
    }
  },
  props: {
    noAddButton: {
      type: Boolean,
      default: false
    },
    isCourseLibrary: {
      default: false
    },
    courses: {
      required: true
    }
  },
  methods: {
    setTaskImage (course) {
      return course.tasks ? course?.tasks[0]?.task_thumbnail : ''
    },
    truncateString (str, num) {
      return str?.length > num ? str.slice(0, num) + "..." : str
    },
    hideModal () {
      this.$emit('close-course-list', {noCourseSelected: !this.selectedCourse.id})
    },
    showModal () {
      this.selectedCourse = {}
      this.$bvModal.show('course-list-modal')
    },
    getCoursesForClassroom () {
      if (!this.courses.length) {
        this.$refs["no_results_modal"].showModal()
        this.getCourses()
      }
    },
    async seeCourseDetails (course) {
      this.coursePdf = course?.pdf_url
      await this.$nextTick()

      this.$refs["course-detail-popup"].openPopup()
    },
    selectCourse: function (course) {
      this.selectedCourse = course
      this.$emit('on-select-course', course)
    },
    closeNoResultsModal: function () {
      this.$refs["no_results_modal"].hideModal()
    }
  },
  computed: {
  },
};
</script>

<style lang="stylus" scoped>
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
.wrapper-classroom-content
  border-top 2px solid color-13
  background-color rgba(color-14, 0.8)
  padding 0 15px 70px 15px
  margin-bottom 25px

  .wrapper-classroom-content__inner
    margin 0px auto
    max-width 1345px
    width 100%
    .container-course-content
      max-width 1190px
      width 100%
      margin 0px auto

    .separator-filters
      width 100%
      height 3px
      background-color color-1
      margin 23px 0px 35px 0px

    .container-courses
      width 100%
      display flex
      flex-wrap wrap
      position relative
      @media (max-width 991px)
        justify-content center

      .course
        text-align center
        font font-opensans-semibold
        width 140px
        height 185px
        padding 5px
        border 2px solid color-3
        color color-3
        border-radius 4px
        margin 0 10px
        position relative
        margin-bottom 10px
        cursor pointer
        background-color color-1

        .course-image
          object-fit cover
          width 100%
          height 80px
          margin-bottom 5px

        .course-name
          text-transform uppercase
          margin-bottom 10px
          width 100%
          word-break break-word

        .course-detail
          bottom 5px
          cursor pointer
          width 100%
          text-decoration underline
          font-size 12px
          position absolute
          margin 0 auto
          left 0

        .course-detail--show-more
          position static
          display flex
          align-items center
          justify-content center
          font-size 14px
          height 100%
          text-transform uppercase

      .course--selected
        border-color color-5
        outline 3px solid color-5

</style>
<style lang="stylus">
@import "~@/core/styles/variables.styl"
@import "~@/core/styles/mixins.styl"
#course-list-modal
  .modal-dialog
    .modal-content
      .modal-body
        background-color rgba(color-14, 0.8)
</style>
