import { render, staticRenderFns } from "./UploadOrRecordPage.vue?vue&type=template&id=2e3746b2&scoped=true"
import script from "./UploadOrRecordPage.vue?vue&type=script&lang=js"
export * from "./UploadOrRecordPage.vue?vue&type=script&lang=js"
import style0 from "./UploadOrRecordPage.vue?vue&type=style&index=0&id=2e3746b2&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e3746b2",
  null
  
)

export default component.exports