<template>
  <div class="form-group">
    <label v-if="label" :for="`checkbox-group-${_uid}`">{{ label }}</label>

    <b-form-checkbox-group
      v-model="inputValue"
      :id="`checkbox-group-${_uid}`"
      :options="options"
      :name="name"
      :class="customClassInput"
      style="column-count: 3;"
      :value-field="valueField"
      :text-field="textField"
      :disabled="disabled"
      @change="(value) => $emit('change', value)"
      :state="state"/>

    <!-- This will only be shown if the preceding input has an invalid state -->
    <b-form-invalid-feedback :id="`checkbox-group-live-feedback-${_uid}`" v-if="showErrors">
      <ul class="error-content">
        <li class="error-text" v-for="(error, index) in errors" :key="`checkbox-group-error-${_uid}-${index}`">
          {{ error }}
        </li>
      </ul>
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import {InputMixins} from "@/core/components/Form/Mixins/input";

export default {
  name: "CheckboxGroup",
  mixins: [InputMixins],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    name: {
      type: String,
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    customClass: {
      type: String,
    },
    valueField: {
      type: String,
      default: 'value'
    },
    textField: {
      type: String,
      default: 'text'
    },
    isObjectArray: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customClassInput() {
      const className = {
        'is-invalid': this.state === false
      }

      className[this.customClass] = !!this.customClass

      return {
        ...className
      }
    },
    inputValue: {
      get: function () {
        let values = []
        for (let i = 0; i < this.value.length; i++) {
          if (this.isObjectArray) {
            values.push(this.value[i][this.valueField])
          } else {
            values.push(this.value[i])
          }
        }
        return values ?? []
      },
      set: function (values) {
        let options = [];

        for (let i = 0; i < values.length; i++) {
          let element

          if (this.isObjectArray) {
            element = this.options.find((el) => el[this.valueField] === values[i])
          } else {
            element = this.options.find((el) => {
              return el[this.valueField] === values[i]
            })[this.valueField]

          }

          if (element) {
            options.push(element)
          }
        }
        this.$emit('input', options)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
