import {http} from './http'
import {AppStorage} from "@/core/Utils";
const resource = "/api/teacher/account";

export default {
  getUser() {
    return AppStorage.getUser()
  },
  getUserProfile() {
    return http.get(`${resource}/info`).then(response => {
      if (response.success) {
        // get local User Information
        let user = AppStorage.getUser()
        // set new user info
        let profile = response.data || {}

        delete profile.token

        const userWhtProfile = Object.assign({}, user, profile)

        AppStorage.setUser(userWhtProfile)

        return userWhtProfile
      } else {
        throw new Error('Error: ' + response.error_code + ' | Message: ' + response.message)
      }
    })
  },
  updateUserProfile(form) {
    return http.put(`${resource}/update`, form).then(response => {
        if (response.success) {
          return this.getUserProfile().then(() => {
            return response
          })
        } else {
          return response
        }
      }
    );
  },
}
