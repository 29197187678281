import {http} from './http'

const qs = require('qs')
const resource = "/api/teacher/classrooms";

export default {
  list(filters = {}) {
    return http.get(`${resource}`, {params: filters})
      .then(response => {
          return response
        }
      );
  },
  create(form = {}) {
    return http.post(`${resource}`, form).then(response => {
      return response
    });
  },
  get(classroom_id) {
    return http.get(`${resource}/${classroom_id}`).then(response => {
      return response
    });
  },
  update(args = {id: 0, form: {}}) {
    const classroom_id = args.id
    const form = args.form
    return http.put(`${resource}/${classroom_id}`, form).then(response => {
      return response
    });
  },
  // Partners
  partnersList(form) {
    return http.get(`${resource}/${form.classroom_id}/partners`, {params: {...form}}).then(response => {
      return response
    })
  },
  partnerConnectionRequest(args = {}) {
    const classroom_id = args.classroom_id
    return http.post(`${resource}/${classroom_id}/partners`, args).then(response => {
      return response
    })
  },
  partnerConnectionList(classroom_id, sort_by = null) {
    return http.get(`/api/teacher/connections`, {
      params: {
        classroom_id: classroom_id,
        sort_by: sort_by
      }
    }).then(response => {
      return response
    })
  },
  partnerConnection(invitation_id) {
    return http.get(`/api/teacher/connections/${invitation_id}`,).then(response => {
      return response
    })
  },
  partnerConnectionResend(invitation_id, message) {
    return http.post(`/api/teacher/connections/${invitation_id}/resend`, {message}).then(response => {
      return response
    })
  },
  partnerConnectionCancel(invitation_id, message) {
    return http.post(`/api/teacher/connections/${invitation_id}/cancel`, {message}).then(response => {
      return response
    })
  },
  partnerConnectionAccept(invitation_id, selected_classroom_id = null) {
    return http.post(`/api/teacher/connections/${invitation_id}/accept`, {selected_classroom_id: selected_classroom_id}).then(response => {
      return response
    })
  },
  partnerConnectionDecline(invitation_id, message) {
    return http.post(`/api/teacher/connections/${invitation_id}/decline`, {message}).then(response => {
      return response
    })
  },
  getSubject(subject_id) {
    return http.get(`/api/teacher/subjects/${subject_id}`,).then(response => {
      return response
    })
  },
  getPartner(args = {classroom_id: Number, partner_id: Number}) {
    const classroom_id = args.classroom_id
    const partner_id = args.partner_id
    return http.get(`${resource}/${classroom_id}/partners/${partner_id}`).then(response => {
      return response
    })
  },
  partnerReport(args = {classroom_id: Number, report: Number}) {
    const classroom_id = args.classroom_id
    const reportText = args.report
    return http.post(`${resource}/${classroom_id}/partner-report`, {report: reportText}).then(response => {
      return response
    })
  },
  reportStudentVideo (data) {
    return http.post('/api/teacher/submissions-partner-report', data).then(response => {
      return response
    })
  },
  getPartnerVideos(args = {classroom_id: 0, filter_by: '', submission_id: ''}) {
    return http.get(`${resource}/${args.classroom_id}/submissions-partner`, {
      params: {filter_by: args.filter_by}
    }).then(response => {
      return response
    })
  },
  getPartnerVideoById({classroom_id, submission_id}) {
    return http.get(`${resource}/${classroom_id}/submissions-partner/${submission_id}`).then(response => {
      return response
    })
  },
  partnerMessageSend(args = {classroom_id: Number, partner_id: Number}) {
    const classroom_id = args.classroom_id
    const partner_id = args.partner_id
    const form = args.form
    return http.post(`${resource}/${classroom_id}/partners/${partner_id}/messages`, form).then(response => {
      return response
    })
  },
  getChatMessages(classroom_id) {
    return http.get(`${resource}/${classroom_id}/partner/messages`).then(response => {
      return response
    })
  },
  sendChatMessages(classroom_id, form) {
    return http.post(`${resource}/${classroom_id}/partner/messages`, form).then(response => {
      return response
    })
  },
  notifyChatMessageRead(classroom_id, message_id) {
    return http.put(`${resource}/${classroom_id}/partner/messages/${message_id}`, {status: 'read'}).then(response => {
      return response
    })
  },
  tasksList(args = {classroom_id: 0, search_string: ''}) {
    return http.get(`${resource}/${args.classroom_id}/tasks`, {params: {search_string: args.search_string}}).then(response => {
      return response
    })
  },
  getSubmission({classroom_id, submission_id}) {
    return http.get(`${resource}/${classroom_id}/submissions/${submission_id}`).then(response => {
      return response
    })
  },
  createTask(args = {classroom_id: 0, form: {}}) {
    return http.post(`${resource}/${args.classroom_id}/tasks`, args.form).then(response => {
      return response
    })
  },
  updateTask(args = {classroom_id: 0, task_id: 0, form: {}}) {
    return http.put(`${resource}/${args.classroom_id}/tasks/${args.task_id}`, args.form).then(response => {
      return response
    })
  },
  setTaskVisibility(args = {classroom_id: Number, task_id: Number, is_hidden: Boolean}) {
    return http.post(`${resource}/${args.classroom_id}/tasks/${args.task_id}/show`, {is_hidden: args.is_hidden}).then(response => {
      return response
    })
  },
  getStudents(args = {classroom_id: 0, filters: {}}) {
    return http.get(`${resource}/${args.classroom_id}/students`, {params: args.filters}).then(response => {
      return response
    })
  },
  importStudents(args = {classroom_id: 0, file: {}, ignore_first_row: false}) {
    let formData = new FormData()
    formData.append("xlsx", args.file)
    formData.append("ignore_first_row", args.ignore_first_row ? 1 : 0)
    return http.post(`${resource}/${args.classroom_id}/students/import`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
  },
  exportStudents(args = {classroom_id: 0, filters: {}}) {
    return http.get(`${resource}/${args.classroom_id}/students/export`, {
      params: {filters: args.filters, columns: args.columns},
      paramsSerializer: params => qs.stringify(params),
      responseType: "blob",
    })
  },
  createStudent(args = {classroom_id: 0, form: {}}) {
    return http.post(`${resource}/${args.classroom_id}/students/create`, args.form).then(response => {
      return response
    })
  },
  editStudentName(args = {classroom_id: 0, form: {}, student_id: ''}) {
    return http.put(`${resource}/${args.classroom_id}/students/${args.student_id}`, args.form).then(response => {
      return response
    })
  },
  createStudentTemplate(args = {classroomId: 0, form: []}) {
    return http.post(`${resource}/${args.classroomId}/students/template`, args.form).then(response => {
      return response
    })
  },
  getStudent(args = {classroom_id: 0, student_id: 0}) {
    return http.get(`${resource}/${args.classroom_id}/students/${args.student_id}`).then(response => {
      return response
    })
  },
  resetStudentPassword(args = {classroom_id: 0, student_id: 0}) {
    return http.put(`${resource}/${args.classroom_id}/students/${args.student_id}/password`).then(response => {
      return response
    })
  },
  deleteStudent(args = {classroom_id: 0, student_id: 0}) {
    return http.delete(`${resource}/${args.classroom_id}/students/${args.student_id}`).then(response => {
      return response
    })
  },
  setStudentPermissions(args = {classroom_id: 0, students: [], approve_student_submissions: Boolean}) {
    return http.put(`${resource}/${args.classroom_id}/students/submissions/permissions`, {
      approve_student_submissions: args.approve_student_submissions,
      students: args.students
    }).then(response => {
      return response
    })
  },
  getStudentVideos(args = {classroom_id: 0, student_id: 0, filter_by: ''}) {
    return http.get(`${resource}/${args.classroom_id}/students/${args.student_id}/videos`, {params: {filter_by: args.filter_by}}).then(response => {
      return response
    })
  },
  getStudentVideosStatus(args = {classroom_id: Number, student_id: Number, form: {}}) {
    return http.put(`${resource}/${args.classroom_id}/students/${args.student_id}/videos`, args.form).then(response => {
      return response
    })
  },
  getStudentResponses(args = {classroom_id: Number, student_id: Number, filter_by: ''}) {
    return http.get(`${resource}/${args.classroom_id}/students/${args.student_id}/responses`, {params: {filter_by: args.filter_by}}).then(response => {
      return response
    })
  },
  setStudentResponsesStatus(args = {classroom_id: Number, student_id: Number, form: {}}) {
    return http.put(`${resource}/${args.classroom_id}/students/${args.student_id}/responses`, args.form).then(response => {
      return response
    })
  },
  getSubmissions(args = {classroom_id: 0, filter_by: '', submission_id: ''}) {
    return http.post(`${resource}/${args.classroom_id}/submissions`, {
      filter_by: args.filter_by,
      submission_id: args.submission_id
    }).then(response => {
      return response
    })
  },
  setSubmissionsStatus(args = {classroom_id: 0, form: {}}) {
    return http.put(`${resource}/${args.classroom_id}/submissions`, args.form).then(response => {
      return response
    })
  },
  saveSubmissionComment(args = {classroomId: 0, submissionId: 0, comment: ''}) {
    return http.put(`${resource}/${args.classroomId}/submissions/${args.submissionId}`, {comment: args.comment }).then(response => {
      return response
    })
  },
  addCourseContent(args = {classroom_id: Number, course_id: Number}) {
    const classroom_id = args.classroom_id
    const course_id = args.course_id
    return http.post(`${resource}/${classroom_id}/courses`, {course_id: course_id}).then(response => {
      return response
    })
  },
  getMessages(args = {classroom_id: Number}) {
    return http.get(`${resource}/${args.classroom_id}/messages`).then(response => {
      return response
    })
  },
  createMessage(args = {classroom_id: 0, form: {}}) {

    return http.post(`${resource}/${args.classroom_id}/messages`, args.form).then(response => {
      return response
    })
  },
  updateMessageStatus(args = {classroom_id: 0, message_id: 0, status: ''}) {
    return http.put(`${resource}/${args.classroom_id}/messages/${args.message_id}`, {status: args.status}).then(response => {
      return response
    })
  },
  resetClassroomCode(classroom_id) {
    return http.post(`${resource}/${classroom_id}/reset-code`).then(response => {
      return response
    });
  },
  saveStudentNumber(args = {classroom_id: 0, students_number: ''}) {
    return http.put(`${resource}/${args.classroom_id}/students-number`, {students_number: args.students_number}).then(response => {
      return response
    })
  },
  getSmallGroupsNames() {
    return http.get(`${resource}/small-group-names`).then(response => {
      return response
    })
  },
  approveGroupsNames(args = {classroom_id: 0, groups: ''}) {
    return http.put(`${resource}/${args.classroom_id}/students/approve-groups-names`, {groups: args.groups}).then(response => {
      return response
    })
  },
  updateGroupName(args = {classroom_id: 0, data: {}}) {
    return http.put(`${resource}/${args.classroom_id}/students/update-student-name`, {id: args.data.id, group_name: args.data.groupName}).then(response => {
      return response
    })
  },
  getSubmissionByTaskId(classroomId, taskId) {
    return http.get(`${resource}/${classroomId}/teacher-submissions/${taskId}`).then(response => {
      return response
    })
  },
  getResponseByTaskId(classroomId, taskId) {
    return http.get(`${resource}/${classroomId}/teacher-submissions-partner/${taskId}`).then(response => {
      return response
    })
  },
  saveSubmissionVideo(classroomId, taskId, url) {
    return http.post(`${resource}/${classroomId}/tasks/${taskId}/videos`, {url: url}).then(response => {
      return response
    })
  },
  saveResponseVideo(classroomId, taskId, url, submissionId) {
    return http.post(`${resource}/${classroomId}/tasks/${taskId}/responses/${submissionId}`, {url: url}).then(response => {
      return response
    })
  },
  getVideoMetadata (url) {
    return http.get(`/api/teacher/files/sizeVideo`, { params: url }).then(response => {
      return response
    })
  },
  async fetchMetadataWithPolling(videoUrl, maxAttempts = 20, interval = 5000) {
    let attempts = 0;
    while (attempts < maxAttempts) {
      try {
        const response = await this.getVideoMetadata({ url: videoUrl })
        if (response.success) {
          return response; // Returns metadata if the answer is successful
        }
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
      // Wait before trying new
      await new Promise((resolve) => setTimeout(resolve, interval));
      attempts++;
    }
    throw new Error("Unable to retrieve metadata after multiple attempts");
  },
  sendVideoData(data, submissionId) {
    return http.put(`/api/teacher/submissions-video-update/${submissionId}`, data).then(response => {
      return response
    })
  }
}